import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import fiveStarTestimonials from './five-star-testimonials.json'
import { Text, Card } from 'theme-ui'
import MemphisPattern from '@components/MemphisPattern'
import ConvertKit from '@widgets/ConvertKit'
import CryptoJS from 'crypto-js'

const ALPHABETS = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
const COUNT = ALPHABETS.length

const createInitial = name => {
  const hash = CryptoJS.MD5(name).toString();
  const first = parseInt(hash.slice(0,2), 16) % COUNT
  const second = parseInt(hash.slice(2,4), 16) % COUNT
  return `${ALPHABETS[first]} ${ALPHABETS[second]}`
}

const AuthorBio = author => {
  const studentName = author['Student Name']
  const initial = createInitial(studentName)

  return (
    <div sx={styles.bio}>
      <div>{author.Comment}</div>

      <Text style={{ textAlign: 'right' }}>{initial}様</Text>
      <Text style={{ textAlign: 'right' }}>{author['Course Name']}</Text>
    </div>
  )
}

const styles = {
  card: {
    position: `relative`
  },
  wrapper: {
    position: `relative`,
    zIndex: 3
  },
  infoColumn: {
    flexBasis: `1`
  },
  innerBox: {
    flexBasis: `1`,
    flexGrow: 1,
    px: [0, 3],
    mt: [3, 0]
  },
  subheader: {
    color: `omegaDark`
  },
  name: {
    textAlign: [`center`, `left`],
    mt: [3, 0],
    px: 3
  },
  bio: {
    textAlign: [`center`, `left`]
  },
  socialList: {
    a: {
      m: 0
    }
  },
  link: {
    position: `absolute`,
    top: 10,
    right: 10,
    zIndex: 3
  },
  pattern: {
    borderRadius: `lg`
  },
  gradient: {
    size: `full`,
    borderRadius: `lg`,
    position: `absolute`,
    left: 0,
    top: 0,
    zIndex: 2,
    background: [
      t =>
        `linear-gradient(0deg, ${t.colors.contentBg} 20%, rgba(255, 255, 255, 0) 80%)`,
      t =>
        `linear-gradient(270deg, ${t.colors.contentBg} 20%, rgba(255, 255, 255, 0) 100%)`
    ]
  }
}

const Testimonials = props => (
  <Layout {...props}>
    <Seo title='受講生様の声' />

    <Divider />

    <Stack>
      <Main>
        <PageTitle
          header='受講生様の声'
          subheader='大変有り難いことに、これまでに多くの受講生様からご満足の声を頂戴しております。その中から一部をご紹介させていただきます。'
        />
      </Main>
    </Stack>

    <Stack>
      <Main>
        {fiveStarTestimonials.map((author, index) => {
          return (
            <div key={index}>
              <Divider />

              <Card variant='paper' sx={styles.card}>
                <AuthorBio {...author} />
                <MemphisPattern sx={styles.pattern} />
              </Card>
            </div>
          )
        })}
      </Main>
    </Stack>

    <ConvertKit dataUid='b0db5e51d6' />
  </Layout>
)

export default Testimonials
